<template>
  <div>
    <app-echart-line
      id="echart"
      :option-data="option"
    />
  </div>
</template>

<script>
import echarts from 'echarts/lib/echarts'
import getFormattedDate, { getFormattedDateTime } from '@/utils/date-formatter'
import currencyFormatter from '@/utils/currency-formatter'
import AppEchartLine from '@core/components/charts/echart/AppEchartLine.vue'

export default {
  components: {
    AppEchartLine,
  },
  props: {
    rows: {
      type: Array,
      default: null,
    },
    field: {
      type: String,
      default: null,
    },
    accountCurrency: {
      type: String,
      default: null,
    },
  },
  data() {
    const vm = this
    return {
      getFormattedDate,
      getFormattedDateTime,
      currency: currencyFormatter,
      myChart: null,
      option: {
        grid: {
          left: 10,
          right: 10,
          top: 15,
          bottom: 10,
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: [],
          axisLabel: {
            formatter: function formatAxisLabel(value) {
              return echarts.format.formatTime('yyyy-MM-dd', value)
            },
          },
          inverse: true,
        },
        yAxis: {
          type: 'value',
          min: 0,
        },
        series: [],
        dataZoom: [
          {
            type: 'inside',
            xAxisIndex: [0],
            start: 0,
            end: 100,
          },
        ],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985',
            },
          },
          formatter: function formatTooltip(params) {
            if (vm.field === 'amount') {
              return `${vm.$i18n.t('sites.date')}: ${params[0].name}<br>${vm.$i18n.t(`reportsTableHeaders.sites.${vm.field}`)}: ${vm.currency(vm.accountCurrency, params[0].value)}`
            }

            return `${vm.$i18n.t('sites.date')}: ${params[0].name}<br>${vm.$i18n.t(`reportsTableHeaders.sites.${vm.field}`)}: ${params[0].value}`
          },
        },
      },
    }
  },
  created() {
    this.fetchStatistics()
  },
  mounted() {
    this.myChart = echarts.init(document.querySelector('#echart'))
  },
  methods: {
    fetchStatistics() {
      const dates = this.rows.map(item => item.datetime)
      const fields = this.rows.map(item => item[this.field])
      const maxShow = Math.max(...fields)
      let increasedMax = Math.ceil(maxShow * 1.3)

      const roundedMax = 10 ** Math.floor(Math.log10(increasedMax))
      increasedMax = Math.ceil(increasedMax / roundedMax) * roundedMax

      this.option.xAxis.data = dates
      this.option.yAxis.max = increasedMax
      this.option.series.push({
        type: 'line',
        data: fields,
      })
    },
  },
}
</script>
<style scoped>
.echarts {
  width: 100% !important
}
</style>
